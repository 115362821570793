import tooltipText from '../config/tooltipTexts';
import { GIGABYTE, MEGABYTE, TERABYTE } from '../constants';
import twentififthPercentile from '../images/icons/first_percentile.png';
import {
  beforeCapacityScale,
  blueWhiteScale,
  fiveGPercentageScale,
  footfallDensityScale,
  meanTonnageScale,
  measurementsScale,
  rsrpScale,
  rssnrScale,
  thirdGenerationPercentScale,
  zeroOrOneScale,
} from './layerConfig.scales';
import type { LayerConfig } from './layerConfig.types';

const buildingConfig: LayerConfig = {
  name: 'Buildings',
  boundingBoxWkt: null,
  description: 'Buildings',
  layerId: 'buildings',
  visibleFromUtc: '2000-01-01T00:00:00Z',
  visibleToUtc: '2099-01-01T00:00:00Z',
  zoomFrom: 0,
  zoomTo: 24,
  defaultSelectedMetric: 'rssnr25Pc',
  defaultLayerOn: false,
  layerParts: [
    {
      from: 0,
      to: 14,
      type: 'tile',
      tilePlotType: 'B',
    },
    {
      from: 14,
      to: 24,
      type: 'geojson',
      layerType: 'fill',
      layerChunkLevel: 15,
      isInteractive: true,
    },
  ],
  metrics: [
    {
      name: 'Mean RSSNR',
      polygonMetricCode: 'rssnrMean',
      scale: rssnrScale,
      description: tooltipText.rssnr,
    },
    {
      name: 'RSSNR 25th Percentile',
      tileMetricCode: 'SNR25',
      polygonMetricCode: 'rssnr25Pc',
      scale: rssnrScale,
      description: tooltipText.rssnr25,
      descriptionImageUrl: twentififthPercentile,
    },
    {
      name: 'Mean RSRP',
      polygonMetricCode: 'rsrpMean',
      scale: rsrpScale,
      description: tooltipText.rsrp,
    },
    {
      name: 'RSRP 25th Percentile',
      tileMetricCode: 'POW25',
      polygonMetricCode: 'rsrp25Pc',
      scale: rsrpScale,
      description: tooltipText.rsrp25,
      descriptionImageUrl: twentififthPercentile,
    },
    {
      name: 'Mean Tonnage',
      tileMetricCode: 'TNG',
      polygonMetricCode: 'monthlyAverageTonnage',
      scale: meanTonnageScale,
      description: tooltipText.meanTonnageDescription,
    },
    {
      name: 'Total Measurements',
      tileMetricCode: 'MSR',
      polygonMetricCode: 'points',
      scale: measurementsScale,
      description: tooltipText.measurements,
    },
    {
      name: 'Footfall Density',
      polygonMetricCode: 'footfallDensity',
      scale: footfallDensityScale,
      description: tooltipText.footfall,
    },
    {
      name: '2G/3G Percentage',
      polygonMetricCode: 'thirdGenerationPercent',
      scale: thirdGenerationPercentScale,
      description: tooltipText.twoGThreeG,
    },
    {
      name: '5G SA Percentage',
      tileMetricCode: '5GP',
      polygonMetricCode: 'fiveGSaPercentage',
      scale: fiveGPercentageScale,
      description: tooltipText.fiveGSa,
    },
    {
      name: '5G NSA Percentage',
      tileMetricCode: '5GNSP',
      polygonMetricCode: 'fiveGNsaPercentage',
      scale: fiveGPercentageScale,
      description: tooltipText.fiveGNsa,
    },
    {
      name: 'Existing Capacity',
      polygonMetricCode: 'beforeCapacity',
      scale: beforeCapacityScale,
      description: tooltipText.existingCapacity,
    },
    {
      name: 'Wired Ratio',
      polygonMetricCode: 'wiredRatio',
      scale: blueWhiteScale,
      description: tooltipText.wiredRatio,
    },
    {
      name: 'Relayed Ratio',
      polygonMetricCode: 'relayedRatio',
      scale: blueWhiteScale,
      description: tooltipText.relayedRatio,
    },
    {
      name: 'Candidates for Dense Air Solution',
      polygonMetricCode: 'candidateForDenseAirSolution',
      scale: zeroOrOneScale,
    },
  ],

  //areaM2
  filters: [
    {
      name: 'Mean RSSNR',
      metricCode: 'rssnrMean',
      type: 'rangeSlider',
      intervals: [
        { label: 'min', value: null },
        { value: -6 },
        { value: -4 },
        { value: -2 },
        { value: 0 },
        { value: 2 },
        { value: 5 },
        { value: 10 },
        { label: 'max', value: null },
      ],
      description: tooltipText.rssnr,
    },
    {
      name: 'RSSNR 25th Percentile',
      metricCode: 'rssnr25Pc',
      type: 'rangeSlider',
      intervals: [
        { label: 'min', value: null },
        { value: -6 },
        { value: -4 },
        { value: -2 },
        { value: 0 },
        { value: 2 },
        { value: 5 },
        { value: 10 },
        { label: 'max', value: null },
      ],
      description: tooltipText.rssnr25,
      descriptionImageUrl: twentififthPercentile,
    },
    {
      name: 'Mean RSRP',
      metricCode: 'rsrpMean',
      type: 'rangeSlider',
      intervals: [
        { label: 'min', value: null },
        { value: -124 },
        { value: -120 },
        { value: -116 },
        { value: -112 },
        { value: -108 },
        { value: -104 },
        { value: -100 },
        { label: 'max', value: null },
      ],
      description: tooltipText.rsrp,
    },
    {
      name: 'RSRP 25th Percentile',
      metricCode: 'rsrp25Pc',
      type: 'rangeSlider',
      intervals: [
        { label: 'min', value: null },
        { value: -124 },
        { value: -120 },
        { value: -116 },
        { value: -112 },
        { value: -108 },
        { value: -104 },
        { value: -100 },
        { label: 'max', value: null },
      ],
      description: tooltipText.rsrp25,
      descriptionImageUrl: twentififthPercentile,
    },
    {
      name: 'Mean Tonnage',
      metricCode: 'monthlyAverageTonnage',
      type: 'rangeSlider',
      intervals: [
        { value: 0 },
        { label: '1MB', value: MEGABYTE },
        { label: '10MB', value: 10 * MEGABYTE },
        { label: '100MB', value: 100 * MEGABYTE },
        { label: '1GB', value: GIGABYTE },
        { label: '10GB', value: 10 * GIGABYTE },
        { label: '100GB', value: 100 * GIGABYTE },
        { label: '1TB', value: TERABYTE },
        { label: 'max', value: null },
      ],
      format: 'bytes',
      description: tooltipText.meanTonnageDescription,
    },
    {
      name: 'Total Measurements',
      metricCode: 'points',
      type: 'rangeSlider',
      intervals: [
        { value: 1 },
        { value: 5 },
        { value: 10 },
        { value: 100 },
        { value: 500 },
        { value: 1000 },
        { value: 2000 },
        { value: 4000 },
        { value: 6000 },
        { value: 10000 },
        { value: 15000 },
        { label: 'max', value: null },
      ],
      description: tooltipText.measurements,
    },
    {
      name: 'Footfall Density',
      metricCode: 'footfallDensity',
      type: 'rangeSlider',
      intervals: [
        { value: 0 },
        { value: 0.001 },
        { value: 0.002 },
        { value: 0.003 },
        { value: 0.004 },
        { value: 0.005 },
        { value: 0.006 },
        { value: 0.007 },
        { value: 0.008 },
        { value: 0.009 },
        { label: 'max', value: null },
      ],
      description: tooltipText.footfall,
    },
    {
      name: '2G/3G Percentage',
      metricCode: 'thirdGenerationPercent',
      type: 'rangeSlider',
      intervals: [
        { value: 0 },
        { value: 2 },
        { value: 5 },
        { value: 10 },
        { value: 15 },
        { value: 20 },
        { value: 25 },
        { value: 30 },
        { label: 'max', value: null },
      ],
      description: tooltipText.twoGThreeG,
    },
    {
      name: '5G SA Percentage',
      metricCode: 'fiveGSaPercentage',
      type: 'rangeSlider',
      intervals: [
        { value: 0 },
        { value: 0.01 },
        { value: 2 },
        { value: 5 },
        { value: 10 },
        { value: 15 },
        { value: 20 },
        { value: 30 },
        { value: 50 },
        { value: 80 },
        { label: 'max', value: null },
      ],
      description: tooltipText.fiveGSa,
    },
    {
      name: '5G NSA Percentage',
      metricCode: 'fiveGNsaPercentage',
      type: 'rangeSlider',
      intervals: [
        { value: 0 },
        { value: 0.01 },
        { value: 2 },
        { value: 5 },
        { value: 10 },
        { value: 15 },
        { value: 20 },
        { value: 30 },
        { value: 50 },
        { value: 80 },
        { label: 'max', value: null },
      ],
      description: tooltipText.fiveGNsa,
    },
    {
      name: 'Area (M2)',
      metricCode: 'areaM2',
      type: 'rangeSlider',
      intervals: [
        { label: 'min', value: null },
        { value: 100 },
        { value: 200 },
        { value: 300 },
        { value: 400 },
        { value: 500 },
        { value: 750 },
        { value: 1000 },
        { value: 2000 },
        { value: 5000 },
        { label: 'max', value: null },
      ],
    },
  ],
  details: [
    {
      sectionTitle: 'Network Activity',
      sectionKey: 'network-activity',

      items: [
        {
          itemKey: 'network-activity-table',
          type: 'grid',
          rows: [
            {
              rowText: 'Total Measurements',
              rowKey: 'total-measurements',
              metricName: 'points',
              showColor: true,
              description: tooltipText.measurements,
            },
            {
              rowText: 'Mean Measurements',
              rowKey: 'mean-measurements',
              metricName: 'monthlyAveragePoints',
              showColor: true,
            },

            {
              rowText: 'Footfall Density',
              rowKey: 'footfall-density',
              metricName: 'footfallDensity',
              showColor: true,
              decimalPlaces: 4,
              description: tooltipText.footfall,
            },
          ],
        },
      ],
    },
    {
      sectionTitle: 'Network Performance',
      sectionKey: 'network-performance',
      navigationOptions: {
        navigationHeading: 'Network Performance',
      },
      items: [
        {
          type: 'grid',
          itemKey: 'building-information-table',
          rows: [
            {
              rowText: 'Mean RSSNR',
              rowKey: 'mean-rssnr',
              metricName: 'rssnrMean',
              showColor: true,
              decimalPlaces: 1,
              description: tooltipText.rssnr,
            },
            {
              rowText: 'RSSNR 25%',
              rowKey: 'rssnr-25pc',
              metricName: 'rssnr25Pc',
              showColor: true,
              decimalPlaces: 1,
              description: tooltipText.rssnr25,
              descriptionImageUrl: twentififthPercentile,
            },
            {
              rowText: 'Mean RSRP',
              rowKey: 'mean-rsrp',
              metricName: 'rsrpMean',
              showColor: true,
              decimalPlaces: 0,
              description: tooltipText.rsrp,
            },
            {
              rowText: 'RSRP 25%',
              rowKey: 'rsrp-25pc',
              metricName: 'rsrp25Pc',
              showColor: true,
              decimalPlaces: 0,
              description: tooltipText.rsrp25,
              descriptionImageUrl: twentififthPercentile,
            },
            {
              rowText: 'Mean Tonnage',
              rowKey: 'mean-tonnage',
              metricName: 'monthlyAverageTonnage',
              showColor: true,
              format: 'bytes',
              description: tooltipText.meanTonnageDescription,
            },
            {
              rowText: 'Mean Measured Upload Throughput (Kb/s)',
              rowKey: 'mean-upload-throughput',
              metricName: 'ultpMeanKbps',
              showColor: false,
            },
            {
              rowText: 'Mean Measured Download Throughput (Kb/s)',
              rowKey: 'mean-download-throughput',
              metricName: 'dltpMeanKbps',
              showColor: false,
            },
            {
              rowText: 'Existing Capacity',
              rowKey: 'existing-capacity',
              metricName: 'beforeCapacity',
              showColor: true,
              decimalPlaces: 2,
              description: tooltipText.existingCapacity,
            },
            {
              rowText: 'Wired Ratio',
              rowKey: 'wired-ratio',
              metricName: 'wiredRatio',
              showColor: true,
              decimalPlaces: 2,
              description: tooltipText.wiredRatio,
            },
            {
              rowText: 'Relay Ratio',
              rowKey: 'relay-ratio',
              metricName: 'relayedRatio',
              showColor: true,
              decimalPlaces: 2,
              description: tooltipText.relayedRatio,
            },
          ],
        },
      ],
    },
    {
      sectionTitle: 'Connection Types Per Technology',
      sectionKey: 'connection-types',
      navigationOptions: {
        navigationHeading: 'Technologies',
      },
      items: [
        {
          itemKey: 'connection-types',
          type: 'pieChart',
          series: [
            {
              label: '5G SA',
              metricName: 'count5G',
              color: '#c37df2',
            },
            {
              label: '5G NSA',
              metricName: 'count5GNsa',
              color: '#ff3cb1',
            },
            {
              label: '4G',
              metricName: 'count4G',
              color: '#1f78b4',
            },
            {
              label: '3G',
              metricName: 'count3G',
              color: '#a6cee3',
            },
          ],
        },
      ],
    },
    {
      sectionTitle: 'Connection Counts (Other)',
      sectionKey: 'connection-counts',
      items: [
        {
          itemKey: 'connection-counts-table',
          type: 'grid',
          rows: [
            {
              rowText: '2G/3G Percentage',
              rowKey: '2g-3g-percentage',
              metricName: 'thirdGenerationPercent',
              showColor: true,
              description: tooltipText.twoGThreeG,
            },

            {
              rowText: 'Call Service Only Measurements Count',
              rowKey: 'call-service-only-measurements-count',
              metricName: 'countCallServiceOnly',
              showColor: false,
            },
            {
              rowText: 'No Service Measurements Count',
              rowKey: 'no-service-measurements-count',
              metricName: 'countNoService',
              showColor: false,
            },
            {
              rowText: 'Other Measurements Count',
              rowKey: 'other-measurements-count',
              metricName: 'countOther',
              showColor: false,
            },
            {
              rowText: 'Wi-Fi Measurements Count',
              rowKey: 'wi-fi-measurements-count',
              metricName: 'countWiFi',
              showColor: false,
            },
          ],
        },
      ],
    },
    {
      // NOTE: THIS IS USED TO GENERATE THE BAND ANALYSIS BUTTON
      sectionTitle: 'Band Analysis',
      sectionKey: 'band-analysis',
      navigationOptions: {
        navigationHeading: 'Band Analysis',
      },
      items: [],
    },
    {
      sectionTitle: 'Building Information',
      sectionKey: 'building-information',
      navigationOptions: {
        navigationHeading: 'Reference Information',
      },
      items: [
        {
          type: 'grid',
          itemKey: 'building-information-table',
          rows: [
            {
              rowText: 'Building Name',
              rowKey: 'buildingName',
              metricName: 'buildingName',
              showColor: false,
            },
            {
              rowText: 'Building ID',
              rowKey: 'buildingId',
              metricName: 'buildingId',
              showColor: false,
              format: 'text',
              enableCopy: true,
            },
            {
              rowText: 'Building Lat / Lon',
              rowKey: 'buildingLatLon',
              metricName: 'locationLatLon',
              showColor: false,
              format: 'text',
              enableCopy: true,
            },
            {
              rowText: 'Area (m<sup>2</sup>)',
              rowKey: 'area',
              metricName: 'areaM2',
              showColor: false,
            },
            {
              rowText: 'Analysis From',
              rowKey: 'fromUtc',
              metricName: 'fromUtc',
              showColor: false,
              format: 'date',
            },
            {
              rowText: 'Analysis To',
              rowKey: 'toUtc',
              metricName: 'toUtc',
              showColor: false,
              format: 'date',
            },
            {
              rowText: 'Provider',
              rowKey: 'provider',
              metricName: 'provider',
              showColor: false,
            },
            {
              rowText: 'WKT',
              rowKey: 'wkt',
              metricName: 'wkt',
              showColor: false,
              format: 'truncated_text',
              enableCopy: true,
            },
          ],
        },
      ],
    },
    {
      sectionTitle: 'Predicted Improvements',
      sectionKey: 'predicted-improvements',
      navigationOptions: {
        navigationHeading: 'Predicted Improvements',
      },
      items: [
        {
          itemKey: 'predicted-improvements-table',
          type: 'grid',
          rows: [
            {
              rowText: 'Calculated Existing Capacity Mb/s',
              rowKey: 'calculated-existing-capacity',
              metricName: 'beforeCapacity',
              showColor: true,
              description: tooltipText.existingCapacity,
            },
            {
              rowText: 'Wired Ratio Multiplier',
              rowKey: 'wired-ratio-multiplier',
              metricName: 'wiredRatio',
              showColor: true,
              description: tooltipText.wiredRatio,
            },

            {
              rowText: 'Relay Ration Multiplier',
              rowKey: 'relay-ratio-multiplier',
              metricName: 'relayedRatio',
              showColor: true,
              decimalPlaces: 4,
              description: tooltipText.relayedRatio,
            },
          ],
        },
      ],
    },
    {
      // NOTE: THIS IS USED TO GENERATE THE ADDITIONAL INFORMATION BUTTON
      sectionTitle: 'Additional Information',
      sectionKey: 'additional-information',
      navigationOptions: {
        navigationHeading: 'Additional Information',
      },
      items: [],
    },
  ],
};

export default buildingConfig;
