import { featureCollection } from '@turf/helpers';
import { Geometry } from 'geojson';
import { FC } from 'react';
import { useMap } from 'react-map-gl';
import { FilterConfig } from '../../helpers/layerConfig.types';
import { useAppSelector } from '../../hooks/useAppSelector';
import { LayerKey } from '../../reducers/layersSlice.types';
import { MccMnc, usePLMNQueryClient } from '../../services/contextClient';
import { AllowedClient, useDynamicClient } from '../../services/dynamicClient';
import ThreeDDraw from './ThreeDDraw';

type ThreeDLayerProps = {
  layerKey: LayerKey;
  layerFilters: FilterConfig[] | undefined;
  minZoom: number;
  maxZoom: number;
};

const ThreeDLayer: FC<ThreeDLayerProps> = (props) => {
  const { layerKey, layerFilters, maxZoom, minZoom } = props;
  const { 'dw-main-map': map } = useMap();

  const countryCode = useAppSelector((state) => state.contextSettings.selectedCountryCode);
  const providerName = useAppSelector((state) => state.contextSettings.selectedProviderName);
  const providerPlmns = usePLMNQueryClient({
    countryCode,
    providerName,
  });

  const client = useDynamicClient(layerKey as AllowedClient);

  const responses = client({
    layerKey,
    providerName,
    countryCode,
    plmns: providerPlmns.isSuccess ? providerPlmns.data : ([] as MccMnc[]),
    startUtc: new Date('2021-01-01'),
    endUtc: new Date('2100-01-01'),
    enabled: minZoom <= (map ? map.getZoom() : 20),
    layerId: layerKey,
  });

  // Pull the features into a single array
  // TODO: Get rid of this `any`, Should be able to do that when there is a generic client
  const responseDataFeatures = responses.flatMap((r: any) => {
    if (r.isSuccess && r.data) {
      return r.data;
    }

    return [];
  });
  // Wrap in FeatureCollection
  const responseCollection = featureCollection<Geometry>(responseDataFeatures);

  return (
    <ThreeDDraw
      layerKey={layerKey}
      data={responseCollection}
      minZoom={minZoom}
      maxZoom={maxZoom}
      layerFilters={layerFilters}
    />
  );
};

export default ThreeDLayer;
