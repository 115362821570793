import { Grid } from '@chakra-ui/react';
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
} from 'chart.js';
import React, { useEffect, useState } from 'react';
import { getFeatureId } from '../../helpers/getFeatureId';
import { useAppSelector } from '../../hooks/useAppSelector';
import { DrawerKey } from '../../reducers/drawersSlice';
import { MccMnc, usePLMNQueryClient } from '../../services/contextClient';
import { AllowedClient, useDynamicClient } from '../../services/dynamicClient';
import OpenLink from '../ActionsModal/OpenLink';

ChartJS.register(
  BarController,
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend
);

type ModalProps = {
  drawerKey: DrawerKey;
};

export type TransformedNodeResponse = {
  data: { normalGraphMetrics: [timeData: string[], metricData: number[]] };
  averageData: { data: [string[], number[]] };
  averageByHour: { hours: string[]; avgByHour: number[] };
  averageHourByDay: { dayHours: string[]; averageValues: number[] };
  metricName: string;
  metricLabel: string;
  nodeName: string | undefined;
};

const DetailsModal: React.FC<ModalProps> = (props) => {
  const { drawerKey } = props;
  const countryCode = useAppSelector((state) => state.contextSettings.selectedCountryCode);
  const providerName = useAppSelector((state) => state.contextSettings.selectedProviderName);
  const providerPlmns = usePLMNQueryClient({
    countryCode,
    providerName,
  });

  const [feature, setFeature] = useState<GeoJSON.Feature | null | undefined>(undefined);

  const featureProperties = feature ? feature.properties : null;

  const selectedFeatureId = useAppSelector((state) => state.mapSettings.selectedFeatureId);

  const client = useDynamicClient(drawerKey as AllowedClient);

  const responses = client({
    layerKey: drawerKey,
    providerName: providerName,
    countryCode: countryCode,
    plmns: providerPlmns.isSuccess ? providerPlmns.data : ([] as MccMnc[]),
    startUtc: new Date('2021-01-01'),
    endUtc: new Date('2100-01-01'),
    enabled: false,
    layerId: drawerKey,
  });

  const responseDataFeatures = responses.flatMap((r: any) => {
    if (r.isSuccess && r.data) {
      return r.data;
    }

    return [];
  });

  useEffect(() => {
    if (responseDataFeatures.length > 0) {
      const feature: GeoJSON.Feature | undefined = responseDataFeatures.find(
        (f: GeoJSON.Feature) => {
          return getFeatureId(f, drawerKey) === selectedFeatureId;
        }
      );

      if (feature) {
        setFeature(feature);
      } else {
        setFeature(undefined);
      }
    }
  }, [responseDataFeatures, selectedFeatureId, drawerKey]);

  const nodeName = featureProperties?.nodeName;

  return (
    <Grid templateColumns={'20px 1fr'} gridGap={'20px'}>
      <OpenLink
        name={'A'}
        nodeName={nodeName}
        type={'analytics'}
        countryCode={countryCode}
        providerName={providerName}
      />
      <OpenLink
        name={'M'}
        nodeName={nodeName}
        type={'monitor'}
        countryCode={countryCode}
        providerName={providerName}
      />
    </Grid>
  );
};

export default DetailsModal;
