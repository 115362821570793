import { featureCollection } from '@turf/helpers';
import { Geometry } from 'geojson';
import isEqual from 'lodash/isEqual';
import _uniqBy from 'lodash/uniqBy';
import { FC } from 'react';
import { useMap } from 'react-map-gl';
import { getFeatureId } from '../../helpers/getFeatureId';
import { FilterConfig, LayerStyles } from '../../helpers/layerConfig.types';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useLayerSettingsSelector } from '../../hooks/useLayersSettings';
import { LayerKey } from '../../reducers/layersSlice.types';
import { MccMnc, usePLMNQueryClient } from '../../services/contextClient';
import { AllowedClient, useDynamicClient } from '../../services/dynamicClient';
import TwoDPolygonDraw from './TwoDPolygonDraw';
import TwoDPolygonMeta from './TwoDPolygonMeta';

type TwoDPolygonLayerProps = {
  layerKey: LayerKey;
  layerType?: 'circle' | 'fill' | 'symbol' | 'line';
  layerFilters: FilterConfig[] | undefined;
  layerStyles?: LayerStyles;
  minZoom: number;
  maxZoom: number;
  cluster?: boolean;
};

const TwoDPolygonLayer: FC<TwoDPolygonLayerProps> = (props) => {
  const { layerKey, layerType, layerFilters, maxZoom, minZoom, layerStyles, cluster } = props;
  const { 'dw-main-map': map } = useMap();

  const countryCode = useAppSelector((state) => state.contextSettings.selectedCountryCode);
  const providerName = useAppSelector((state) => state.contextSettings.selectedProviderName);
  const providerPlmns = usePLMNQueryClient({
    countryCode,
    providerName,
  });

  const chunks = useLayerSettingsSelector(layerKey, 'visibleChunks', [], isEqual);

  const client = useDynamicClient(layerKey as AllowedClient);

  const responses = client({
    layerKey,
    providerName,
    countryCode,
    plmns: providerPlmns.isSuccess ? providerPlmns.data : ([] as MccMnc[]),
    startUtc: new Date('2021-01-01'),
    endUtc: new Date('2100-01-01'),
    enabled: minZoom <= (map ? map.getZoom() : 20),
    layerId: layerKey,
  });

  // Pull the features into a single array
  // TODO: Get rid of this `any`, Should be able to do that when there is a generic client
  const responseDataFeatures = responses.flatMap((r: any) => {
    if (r.isSuccess && r.data) {
      return r.data;
    }

    return [];
  });

  // Get unique features
  const uniqueFeatures = _uniqBy(responseDataFeatures, (f) => {
    return getFeatureId(f, layerKey);
  });

  // Wrap in FeatureCollection
  const responseCollection = featureCollection<Geometry>(uniqueFeatures);

  if (countryCode && providerName && chunks) {
    return (
      <>
        <TwoDPolygonDraw
          data={responseCollection}
          cluster={cluster}
          layerKey={layerKey}
          layerType={layerType ?? 'fill'}
          minZoom={minZoom}
          maxZoom={maxZoom}
          layerFilters={layerFilters}
          layerStyles={layerStyles}
        />
        <TwoDPolygonMeta layerKey={layerKey} features={responseDataFeatures} />
      </>
    );
  }

  return null;
};

export default TwoDPolygonLayer;
